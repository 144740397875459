.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #CD1719;
  color: #fff;
}
::selection {
  background: #CD1719;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(205, 23, 25, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.9375rem + 0.0625
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.9375rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #1D1D1B;
  font-family: "Roboto", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.9375rem + 0.0625
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
}
h1,
.style_h1 {
  letter-spacing: calc((1.625rem + 0.3125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.625rem;
  color: #1D1D1B;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.625rem + 0.3125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 1.9375rem;
  }
}
.like_h2,
h2 {
  letter-spacing: calc((1.375rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.375rem;
  color: #CD1719;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 1.5em;
}
@media (min-width: 20rem) {
  .like_h2,
  h2 {
    font-size: calc(1.375rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  .like_h2,
  h2 {
    font-size: 1.5rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.1875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #1D1D1B;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.1875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.3125rem;
  }
}
.like_h4,
h4 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #1D1D1B;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  .like_h4,
  h4 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  .like_h4,
  h4 {
    font-size: 1rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #1D1D1B;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1rem;
  }
}
h6,
.style_h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #CD1719;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #1D1D1B;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(205, 23, 25, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
  display: none;
}
a.touch_link {
  color: #1D1D1B;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #CD1719;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #1D1D1B;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(205, 23, 25, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #dddddd;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #dddddd;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f6f6f6;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #CD1719;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg");
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list_entry dl {
  border-bottom: 0.0625rem solid #dddddd;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list_entry dt {
  border-top: 0.0625rem solid #dddddd;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list_entry dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dd {
    border-top: 0.0625rem solid #dddddd;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
.news_entity .hide_creation_date {
  display: none;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #1D1D1B;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #5f5f58;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #5f5f58;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #5f5f58;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #CD1719;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #CD1719;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #1D1D1B;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #dddddd;
  border-radius: 0;
  box-shadow: 0 0 0 #dddddd inset;
  color: #1D1D1B;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #CD1719;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #CD1719;
  box-shadow: 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
  color: #1D1D1B;
}
.input:focus,
.textarea:focus {
  border-color: #CD1719;
  box-shadow: 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
  color: #1D1D1B;
}
.input {
  height: 2.75rem;
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.5;
  overflow: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #CD1719;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #CD1719;
  box-shadow: 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
  color: #1D1D1B;
}
.control input:focus ~ .indicator {
  border-color: #CD1719;
  box-shadow: 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
  color: #1D1D1B;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #dddddd;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #1D1D1B;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #dddddd;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem #dddddd inset;
  color: #1D1D1B;
  cursor: pointer;
  display: block;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #1D1D1B;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #CD1719;
}
.select.error.focus {
  border-color: #CD1719;
  box-shadow: 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
  color: #1D1D1B;
}
.select.focus {
  border-color: #CD1719;
  box-shadow: 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
  color: #1D1D1B;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #1D1D1B;
  cursor: pointer;
  height: calc(2.75rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1D1D1B;
}
.select select:focus::-ms-value {
  background: none;
  color: #1D1D1B;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(205, 23, 25, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(205, 23, 25, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(205, 23, 25, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(205, 23, 25, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  85% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  85% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #dddddd;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #CD1719;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #CD1719;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #CD1719;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #CD1719;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    margin-right: 0.3125rem;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_wrapper {
    position: relative;
  }
  .author_wrapper .author_hor {
    display: inline-block;
    fill: #86867e;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.5625rem;
    top: 0.5625rem;
    width: 4.6875rem;
  }
  .breadcrumbs {
    display: none;
  }
  .button {
    border-radius: 0;
    font-size: 1rem;
    padding: 0 1.5em;
    background: #CD1719;
    border: 0.0625rem solid #CD1719;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button .icon {
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .icon svg {
    fill: #fff;
  }
  .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
    outline: 0;
    background: #DA5B41;
    border-color: #DA5B41;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #DA5B41;
    border-color: #DA5B41;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #CD1719 inset;
    background: #CD1719;
    border: 0.0625rem solid #CD1719;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button:active .icon,
  .button.active .icon {
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .icon svg,
  .button.active .icon svg {
    fill: #fff;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #CD1719;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #CD1719;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .steuernews_call_to_action {
    background: #f6f6f6;
    border-radius: 0;
    display: inline-block;
    margin: 0 0 1.5em;
    padding: 0.75em;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .chart_organigram {
    display: none;
  }
  .google-visualization-orgchart-table {
    border-bottom: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google-visualization-orgchart-table .node {
    background: #f6f6f6;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 85%;
    padding: 0.4375rem;
    vertical-align: middle;
  }
  .google-visualization-orgchart-table .google-visualization-orgchart-linenode {
    border-color: #dddddd;
    border-top: 0;
  }
  .edit_in_cms {
    display: none;
  }
  .company_logo {
    display: block;
    width: 13.125rem;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #f6f6f6;
    border-top: 0.0625rem solid #dddddd;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 9000;
  }
  .cookie_banner p {
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
    width: 100%;
  }
  .cookie_banner .close {
    cursor: pointer;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    margin-bottom: 0.75em;
  }
  .cookie_banner .close .text {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0 0.3em;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link:hover {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .download_overview .touch_link:hover .icon {
    fill: #CD1719;
    opacity: 1;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0;
    padding-top: 0;
  }
  .download_overview .touch_link .title {
    color: #1D1D1B;
    margin-bottom: 0;
    text-decoration: none;
  }
  .download_overview .icon {
    fill: #1D1D1B;
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.75em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.5rem;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu a {
    text-decoration: none;
  }
  .dropdown_menu .nav_elements {
    max-width: 42.1875rem;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    position: relative;
    text-transform: none;
  }
  .dropdown_menu > ul .logo {
    -webkit-box-pack: center;
            justify-content: center;
    padding-top: 1.5em;
  }
  .dropdown_menu > ul:first-child {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .dropdown_menu > ul:last-child {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .dropdown_menu > ul:last-child > li:last-child ul {
    -webkit-transform: translateX(-7.2rem);
            transform: translateX(-7.2rem);
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li > a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li .icon {
    box-sizing: content-box;
    display: inline-block;
    height: 0.9375rem;
    padding-left: 0.9375rem;
    width: 0.9375rem;
  }
  .dropdown_menu > ul > li .icon svg {
    fill: #CD1719;
  }
  .dropdown_menu > ul > li:hover > a,
  .dropdown_menu > ul > li.hover > a {
    color: #CD1719;
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    visibility: visible;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #1D1D1B;
    display: -webkit-box;
    display: flex;
    font-size: 1rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.75em 1.25em;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a.active {
    background: #1D1D1B;
    color: #fff;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    box-shadow: 0 0 1.25rem -0.9375rem #c3c3c3;
    padding: 0.75em 0;
    position: absolute;
    text-transform: none;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul li:hover ul {
    display: block !important;
  }
  .dropdown_menu > ul > li ul a {
    color: #1D1D1B;
    display: block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.75em;
    white-space: nowrap;
    font-size: 1rem;
  }
  .dropdown_menu > ul > li ul a:hover {
    color: #CD1719;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #CD1719;
  }
  .dropdown_menu > ul > li ul ul {
    background: #fff;
    box-shadow: 0 0 1.25rem -0.9375rem #c3c3c3;
    padding: 0.75em 0;
    text-transform: none;
    visibility: hidden;
    z-index: 1000;
    display: none;
    position: absolute;
    top: 140px;
    left: -55%;
  }
  .dropdown_menu > ul > li ul ul:target {
    visibility: visible;
    display: block;
  }
  .dropdown_menu > ul > li ul ul:hover {
    display: block;
  }
  .dropdown_menu > ul > li ul ul a {
    color: #1D1D1B;
    display: block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.75em;
    white-space: nowrap;
    font-size: 0.9375rem;
  }
  .dropdown_menu > ul > li ul ul a:hover {
    color: #CD1719;
  }
  .dropdown_menu > ul > li ul ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul ul a.active {
    color: #CD1719;
  }
  footer {
    background: #fff;
    border-top: 1px solid #b8b8b2;
    color: #1D1D1B;
    padding: 3em 0 4.5em;
  }
  footer a {
    color: #1D1D1B;
  }
  footer a.active {
    color: #CD1719;
    text-decoration: none;
  }
  footer .company_name {
    font-size: 0.9375rem;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0.75em !important;
  }
  footer .location_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    padding-bottom: 1.5em;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  footer .location_wrapper .location {
    font-size: 0.9375rem;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0.75em;
  }
  footer .location_wrapper .address_footer {
    padding-bottom: 3em;
  }
  footer .location_wrapper p {
    margin-bottom: 0;
  }
  footer .location_wrapper .button {
    background: #fff;
    border: 0.0625rem solid #fff;
    color: #1D1D1B;
  }
  footer .service_wrapper {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding-bottom: 1.5em;
  }
  footer .service_wrapper .row {
    width: 100%;
  }
  footer .service_wrapper p {
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0;
  }
  footer .service_wrapper .link_wrapper {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding-bottom: 0.75em;
  }
  footer .service_wrapper .related_links {
    padding-bottom: 0.75em;
  }
  footer .service_wrapper .button_wrapper_footer {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    position: relative;
    width: 100%;
  }
  footer .service_wrapper .button_wrapper_footer a {
    font-size: 90%;
    margin: 0 0.3125rem 0.625rem;
  }
  footer .service_wrapper .button_wrapper_footer .facebook,
  footer .service_wrapper .button_wrapper_footer .instagram {
    background: none;
    border: 0;
    padding: 0;
    margin-right: 0.5em;
  }
  footer .service_wrapper .button_wrapper_footer .facebook .icon,
  footer .service_wrapper .button_wrapper_footer .instagram .icon {
    height: 2.375rem;
    width: 2.375rem;
  }
  footer .service_wrapper .button_wrapper_footer .facebook .icon svg,
  footer .service_wrapper .button_wrapper_footer .instagram .icon svg {
    height: 2.375rem;
    width: 2.375rem;
  }
  footer .service_wrapper .button_wrapper_footer .footer_labels {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    /*@media (min-width: @large_grid_breakpoint) {
                    position: absolute;
                    right: (115 / @rem);
                    bottom: (25 / @rem);
                }
                
                @media (min-width: @xxxlarge_grid_breakpoint) {
                    position: absolute;
                    right: (0 / @rem);
                    bottom: (50 / @rem);
                }*/
  }
  footer .service_wrapper .button_wrapper_footer .footer_labels figure {
    max-width: 6.25rem !important;
  }
  footer .service_wrapper .button_wrapper_footer .footer_labels figure.exzellenter_arbeitgeber {
    max-width: 5.625rem !important;
    margin-right: 1em;
  }
  .pre_footer {
    border-top: 1px solid #b8b8b2;
    margin-top: 1em;
    padding-top: 1.5em;
  }
  .pre_footer .pre_footer_labels {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    flex-wrap: wrap;
    margin: 3em 0;
  }
  .pre_footer .pre_footer_labels .figure {
    margin: 0.75em;
    position: relative;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .pre_footer .pre_footer_labels .figure:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #CD1719;
    -webkit-transition: width .3s;
    transition: width .3s;
    margin-top: 0.75em;
  }
  .pre_footer .pre_footer_labels .figure:hover {
    -webkit-transform: scale(1.125);
            transform: scale(1.125);
  }
  .pre_footer .pre_footer_labels .figure:hover:after {
    width: 100%;
  }
  .pre_footer .content_slider .pre_footer_labels {
    -webkit-box-align: center;
            align-items: center;
    justify-content: space-around;
  }
  .pre_footer .content_slider .pre_footer_labels .smaller_label img {
    max-height: 2rem;
    width: auto;
  }
  .go_to_top {
    background: #f6f6f6;
    border-radius: 0;
    position: fixed;
    right: 1.5em;
    top: 80%;
    z-index: 90;
  }
  .go_to_top .icon {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    fill: #CD1719;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    width: 2.75rem;
  }
  .go_to_top .icon svg {
    height: 2rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 2rem;
  }
  .form_disclaimer {
    background: #f6f6f6;
    border-radius: 0;
    color: #1D1D1B;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 2.75rem;
    margin: 0 0 1em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #CD1719;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #dddddd;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.1875rem #dddddd inset;
    color: #1D1D1B;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 2.75rem;
    line-height: calc(2.75rem - (0.0625rem * 2));
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background: #CD1719;
    border: 0.0625rem solid #CD1719;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .file .file_button .icon {
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .icon svg {
    fill: #fff;
  }
  .file .file_button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
    background: #DA5B41;
    border-color: #DA5B41;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2.75rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #DA5B41;
    border-color: #DA5B41;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #CD1719 inset;
    background: #CD1719;
    border: 0.0625rem solid #CD1719;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .icon svg {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    height: 2.75rem;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #f6f6f6;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #dddddd;
    border-color: #d0d0d0;
    color: #1D1D1B;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #1D1D1B;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
    z-index: 15;
    background: #e82f31;
    border-color: #b61416;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #dddddd;
    color: #1D1D1B;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .google_maps .travel_mode_radio .icon {
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .icon svg {
    fill: #fff;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #1D1D1B;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio .icon svg {
    fill: #1D1D1B;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #CD1719;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 1.5em 3em;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #dddddd;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #dddddd;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .maps_wrapper .inner_direction {
    -webkit-box-align: center;
            align-items: center;
    background: #1D1D1B;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    min-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    min-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
    min-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    min-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    min-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    min-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
    min-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    min-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    min-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    overflow: hidden;
    position: relative;
  }
  header .header_wrapper {
    height: 4.6875rem;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: #fff;
    box-shadow: 0 -15px 15px 15px rgba(0, 0, 0, 0.5);
  }
  header .header_wrapper .container {
    height: 100%;
    align-content: center;
  }
  header .nav_wrapper {
    position: relative;
  }
  header .nav_wrapper .mobile_nav {
    display: -webkit-box;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
  }
  header .nav_wrapper .mobile_nav .call {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin-right: 0.25rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  header .nav_wrapper .mobile_nav .call:hover .icon {
    fill: #CD1719;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  header .nav_wrapper .mobile_nav .call .icon svg {
    height: 2.5rem;
    width: 2.125rem;
  }
  header .nav_wrapper .mobile_nav .call,
  header .nav_wrapper .mobile_nav .toggle_search_box,
  header .nav_wrapper .mobile_nav .social_media_links {
    display: none;
  }
  header .nav_wrapper .mobile_nav .link_contact .icon svg {
    height: 2.1875rem;
    width: 2.1875rem;
  }
  header .nav_wrapper .mobile_nav .video {
    display: none;
  }
  header .logo_nav_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .header_picture {
    position: relative;
    margin-top: 4.6875rem;
  }
  .header_picture .header_description {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0 1.5em;
    position: absolute;
    text-align: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .header_picture .header_description h1 {
    color: #fff;
  }
  .header_picture .header_description .sitetitle {
    font-size: 1.125rem;
    font-family: "Roboto", sans-serif;
    margin: 0 auto;
    margin-bottom: 0;
    max-width: 59.375rem;
  }
  .header_picture .header_description .description {
    margin: 0 auto;
    max-width: 43.75rem;
    padding-bottom: 1.5em;
  }
  .header_picture .header_description .button {
    text-transform: none;
  }
  .label_header {
    position: absolute;
    bottom: 0.625rem;
    left: 0.625rem;
    z-index: 109;
    -webkit-animation: 1200ms fadeIn;
            animation: 1200ms fadeIn;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .label_header.label_header_slider {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: end;
            align-items: end;
  }
  .label_header.label_header_slider figure {
    margin-bottom: 1.5em !important;
  }
  .label_header .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: end;
  }
  .label_header figure {
    display: block;
    margin-bottom: 0;
    margin-right: 1.5625rem;
  }
  .label_header figure img {
    max-width: 5rem;
  }
  .label_header .digi_kanzlei img {
    margin-bottom: -0.5rem;
  }
  .karriere .header_description {
    display: none;
  }
  .header_picture img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .image_gallery_overview {
    margin-bottom: 1.5em;
    position: relative;
  }
  .image_gallery_entry {
    background-color: #f6f6f6;
    border-bottom: 0.1875rem solid #fff;
    border-right: 0.1875rem solid #fff;
    display: block;
    position: relative;
  }
  .image_gallery_entry:focus,
  .image_gallery_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_gallery_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry:focus .open svg,
  .image_gallery_entry:hover .open svg {
    -webkit-animation: news_photo 900ms;
            animation: news_photo 900ms;
  }
  .image_gallery_entry .open {
    background: rgba(29, 29, 27, 0.5);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg {
    height: 3.75rem;
    margin: auto;
    width: 3.75rem;
  }
  .image_gallery_entry .image {
    background-position: 50%;
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
  }
  .image_gallery_overlay {
    background: rgba(29, 29, 27, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline;
    height: 100%;
    margin: 0;
  }
  .image_gallery_overlay .full_image figcaption {
    background: rgba(29, 29, 27, 0.5);
    bottom: 0;
    color: #fff;
    display: block;
    padding: 0.75em;
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .image_gallery_overlay .full_image img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #CD1719;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    height: 2.75rem;
    margin-top: -1.375rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    fill: #CD1719;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    fill: #fff;
    height: 2.75rem;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 2%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 2%;
  }
  .image_gallery_overlay nav .close {
    background: #fff;
    height: 2.75rem;
    padding: 0.375rem;
    right: 0;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    outline: 0;
  }
  .image_gallery_overlay nav .close:focus .icon,
  .image_gallery_overlay nav .close:hover .icon {
    fill: #CD1719;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #1D1D1B;
    height: 2rem;
    width: 2rem;
  }
  .infolist_overview {
    margin-bottom: 0.75em;
  }
  .infolist_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .infolist_overview .touch_link:hover {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .infolist_overview .touch_link:hover .icon {
    fill: #CD1719;
    opacity: 1;
    -webkit-transform: translateY(35%);
            transform: translateY(35%);
  }
  .infolist_overview .touch_link .title_wrapper {
    margin-bottom: 0;
    padding-top: 0;
  }
  .infolist_overview .touch_link .title {
    color: #1D1D1B;
    margin-bottom: 0;
    text-decoration: none;
  }
  .infolist_overview .icon {
    fill: #1D1D1B;
    flex-shrink: 0;
    height: 1.625rem;
    margin-right: 0.75em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.625rem;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #CD1719;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #f6f6f6;
    border-radius: 0;
    color: #1D1D1B;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #f6f6f6;
    border-radius: 0;
    color: #1D1D1B;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #dddddd;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .infolist_anchor_overview {
    background: #f6f6f6;
    border-radius: 0;
    padding: 0.75em 1.5em;
  }
  .infolist_anchor_overview a {
    display: block;
    padding: 0.75em 0;
  }
  .infolist_anchor_overview li {
    border-top: 0.0625rem solid #dddddd;
  }
  .infolist_anchor_overview li:first-child {
    border-top: 0;
  }
  .line_chart {
    border: 0.0625rem solid #dddddd;
    height: 25rem;
    margin-bottom: 1.5em;
  }
  .switch_view {
    margin-bottom: 0.75em;
  }
  .map_chart {
    border: 0.0625rem solid #dddddd;
    height: 28.75rem;
    margin: 0 0 1.5em;
    padding: 0.375em;
  }
  .jvectormap-label,
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    background: #f6f6f6;
    border: 0.0625rem solid #dddddd;
    color: #1D1D1B;
    position: absolute;
  }
  .jvectormap-label {
    border-radius: 0;
    display: none;
    padding: 0.375em 0.75em;
    white-space: nowrap;
    z-index: 9999;
  }
  .jvectormap-label .amount {
    display: inline;
  }
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    cursor: pointer;
    height: 2.5rem;
    left: 0.625rem;
    line-height: 2.5rem;
    text-align: center;
    width: 2.5rem;
  }
  .jvectormap-zoomin {
    border-radius: 0 0 0 0;
    top: 0.625rem;
  }
  .jvectormap-zoomout {
    border-radius: 0 0 0 0;
    top: 3.125rem;
  }
  main {
    padding-top: 3em;
  }
  main .text_startpage {
    margin: 0 auto;
    max-width: 69.375rem;
    padding-bottom: 3em;
    text-align: left;
  }
  main .text_startpage h1 {
    text-transform: none;
    font-family: "Roboto", sans-serif;
  }
  main h1 {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
  main .main_content h2 {
    padding-top: 0;
  }
  main .wrapper_contact {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
  main .overview .touch_link .title {
    color: #1D1D1B;
    text-decoration: none;
  }
  main .overview .touch_link:hover .title {
    color: #CD1719;
  }
  iframe {
    border: 0;
    frameborder: 0!important;
    framespacing: 0;
    width: 100%;
  }
  .tinyletters {
    text-transform: lowercase !important;
  }
  .partner_image_portrait {
    background: white;
    padding: 0.625rem;
  }
  .partner .slider .slider_image_wrapper .description_wrapper .description {
    padding-bottom: 0;
  }
  .partner .slider .control_slides {
    display: block;
    height: 2.1875rem;
    position: absolute;
    top: 86%;
    width: 2.1875rem;
    z-index: 15;
  }
  .portrait .header_picture {
    height: 1250px;
  }
  .portrait .folder_content {
    background: rgba(255, 255, 255, 0.75);
    margin: 0 auto;
    padding: 0.75em;
    position: absolute;
    right: 15%;
    top: 65%;
    z-index: 999;
  }
  .portrait .folder_content .partner_img_small {
    max-width: 100% !important;
  }
  .portrait .folder_content .partner_img_small img {
    max-height: 140px;
    width: auto;
    height: 100%;
  }
  .portrait .folder_content .partner_img_small figure {
    width: 100%;
  }
  .portrait .folder_content .partner_img_small figure figcaption {
    font-size: 85%;
    text-align: left;
    background: white;
    padding: 0.625rem;
    margin-top: 0;
    width: 100%;
  }
  .portrait .folder_content a {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-decoration: none;
  }
  .portrait .folder_content a h2 {
    margin: 0;
    padding: 0 0 0.375em 0;
    text-transform: capitalize;
    text-decoration: underline;
  }
  .portrait .folder_content a h2:hover {
    color: #991113;
  }
  .portrait .folder_content a p {
    color: #1D1D1B;
  }
  .portrait .folder_content a img {
    width: 14.375rem;
    height: auto;
  }
  .portrait .header_description {
    display: none;
  }
  .share_button {
    z-index: 99999;
  }
  .normal_letters {
    text-transform: none;
  }
  .border_dankesseite {
    border: 0.5rem solid #CD1719;
    border-radius: 20px;
    margin-bottom: 1.5em;
    padding: 0 1.2em;
  }
  .button_dankesseite {
    margin-top: 2.25em;
  }
  .hideme_mobile {
    display: none !important;
  }
  .hideme_desktop {
    display: block !important;
  }
  .berater .main_content {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .line_under_headerline .entry > .title {
    margin: 3.75em 0 1.5em;
    padding-bottom: 0.5em;
    position: relative;
    text-transform: unset;
    font-weight: 300 !important;
  }
  .line_under_headerline .entry > .title::after {
    display: block;
    content: '';
    width: 100%;
    height: 0.0625rem;
    background: #1D1D1B;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .content_slider {
    position: relative;
  }
  .content_slider .slide_wrapper {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    position: relative;
  }
  .content_slider .slide_wrapper .slide {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    -webkit-box-flex: 1;
            flex: 1 0 100%;
    max-width: 100%;
    -webkit-transition: -webkit-transform 900ms;
    transition: -webkit-transform 900ms;
    transition: transform 900ms;
    transition: transform 900ms, -webkit-transform 900ms;
    z-index: 10;
  }
  .content_slider .navigation_wrapper {
    display: -webkit-box;
    display: flex;
    position: relative;
    z-index: 30;
  }
  .content_slider .dot {
    background-color: #fff;
    border: 0.125rem solid #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 0.9375rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 0.9375rem;
    display: none;
  }
  .content_slider .dot_1 {
    background-color: #1D1D1B;
  }
  .content_slider .slide_prev,
  .content_slider .slide_next {
    background-repeat: no-repeat;
    bottom: 0;
    cursor: pointer;
    display: block;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.375rem;
    height: 1.375rem;
    z-index: 20;
  }
  .content_slider .slide_prev.hidden,
  .content_slider .slide_next.hidden {
    cursor: default;
    opacity: 0;
  }
  .content_slider .slide_prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 20px solid #CD1719;
    border-bottom: 15px solid transparent;
  }
  .content_slider .slide_next {
    right: 0;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 20px solid #CD1719;
    border-bottom: 15px solid transparent;
  }
  .new_slider {
    position: relative;
  }
  .new_slider:focus {
    outline: 0;
  }
  .new_slider .slides_wrapper {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    overflow: hidden;
  }
  .new_slider .slides_wrapper .slides {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
  }
  .new_slider .slides_wrapper .slides .slide {
    opacity: 0;
    position: relative;
    visibility: hidden;
    width: 100%;
    /*@for $position from -100 through 100 {
                    &.slide[data-position='#{$position * 100}'] {
                        transform: translateX($position * 100%);
                    }
                }*/
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-3000"] {
    -webkit-transform: translateX(-3000%);
            transform: translateX(-3000%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2900"] {
    -webkit-transform: translateX(-2900%);
            transform: translateX(-2900%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2800"] {
    -webkit-transform: translateX(-2800%);
            transform: translateX(-2800%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2700"] {
    -webkit-transform: translateX(-2700%);
            transform: translateX(-2700%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2600"] {
    -webkit-transform: translateX(-2600%);
            transform: translateX(-2600%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2500"] {
    -webkit-transform: translateX(-2500%);
            transform: translateX(-2500%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2400"] {
    -webkit-transform: translateX(-2400%);
            transform: translateX(-2400%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2300"] {
    -webkit-transform: translateX(-2300%);
            transform: translateX(-2300%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2200"] {
    -webkit-transform: translateX(-2200%);
            transform: translateX(-2200%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2100"] {
    -webkit-transform: translateX(-2100%);
            transform: translateX(-2100%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-2000"] {
    -webkit-transform: translateX(-2000%);
            transform: translateX(-2000%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1900"] {
    -webkit-transform: translateX(-1900%);
            transform: translateX(-1900%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1800"] {
    -webkit-transform: translateX(-1800%);
            transform: translateX(-1800%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1700"] {
    -webkit-transform: translateX(-1700%);
            transform: translateX(-1700%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1600"] {
    -webkit-transform: translateX(-1600%);
            transform: translateX(-1600%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1500"] {
    -webkit-transform: translateX(-1500%);
            transform: translateX(-1500%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1400"] {
    -webkit-transform: translateX(-1400%);
            transform: translateX(-1400%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1300"] {
    -webkit-transform: translateX(-1300%);
            transform: translateX(-1300%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1200"] {
    -webkit-transform: translateX(-1200%);
            transform: translateX(-1200%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1100"] {
    -webkit-transform: translateX(-1100%);
            transform: translateX(-1100%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-1000"] {
    -webkit-transform: translateX(-1000%);
            transform: translateX(-1000%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-900"] {
    -webkit-transform: translateX(-900%);
            transform: translateX(-900%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-800"] {
    -webkit-transform: translateX(-800%);
            transform: translateX(-800%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-700"] {
    -webkit-transform: translateX(-700%);
            transform: translateX(-700%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-600"] {
    -webkit-transform: translateX(-600%);
            transform: translateX(-600%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-500"] {
    -webkit-transform: translateX(-500%);
            transform: translateX(-500%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-400"] {
    -webkit-transform: translateX(-400%);
            transform: translateX(-400%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-300"] {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-200"] {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="-100"] {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="0"] {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="100"] {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="200"] {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="300"] {
    -webkit-transform: translateX(300%);
            transform: translateX(300%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="400"] {
    -webkit-transform: translateX(400%);
            transform: translateX(400%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="500"] {
    -webkit-transform: translateX(500%);
            transform: translateX(500%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="600"] {
    -webkit-transform: translateX(600%);
            transform: translateX(600%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="700"] {
    -webkit-transform: translateX(700%);
            transform: translateX(700%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="800"] {
    -webkit-transform: translateX(800%);
            transform: translateX(800%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="900"] {
    -webkit-transform: translateX(900%);
            transform: translateX(900%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1000"] {
    -webkit-transform: translateX(1000%);
            transform: translateX(1000%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1100"] {
    -webkit-transform: translateX(1100%);
            transform: translateX(1100%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1200"] {
    -webkit-transform: translateX(1200%);
            transform: translateX(1200%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1300"] {
    -webkit-transform: translateX(1300%);
            transform: translateX(1300%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1400"] {
    -webkit-transform: translateX(1400%);
            transform: translateX(1400%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1500"] {
    -webkit-transform: translateX(1500%);
            transform: translateX(1500%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1600"] {
    -webkit-transform: translateX(1600%);
            transform: translateX(1600%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1700"] {
    -webkit-transform: translateX(1700%);
            transform: translateX(1700%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1800"] {
    -webkit-transform: translateX(1800%);
            transform: translateX(1800%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="1900"] {
    -webkit-transform: translateX(1900%);
            transform: translateX(1900%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="2000"] {
    -webkit-transform: translateX(2000%);
            transform: translateX(2000%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3100"] {
    -webkit-transform: translateX(3100%);
            transform: translateX(3100%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3200"] {
    -webkit-transform: translateX(3200%);
            transform: translateX(3200%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3300"] {
    -webkit-transform: translateX(3300%);
            transform: translateX(3300%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3400"] {
    -webkit-transform: translateX(3400%);
            transform: translateX(3400%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3500"] {
    -webkit-transform: translateX(3500%);
            transform: translateX(3500%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3600"] {
    -webkit-transform: translateX(3600%);
            transform: translateX(3600%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3700"] {
    -webkit-transform: translateX(3700%);
            transform: translateX(3700%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3800"] {
    -webkit-transform: translateX(3800%);
            transform: translateX(3800%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="3900"] {
    -webkit-transform: translateX(3900%);
            transform: translateX(3900%);
  }
  .new_slider .slides_wrapper .slides .slide.slide[data-position="4000"] {
    -webkit-transform: translateX(4000%);
            transform: translateX(4000%);
  }
  .new_slider .slides_wrapper .slides .slide.no_grid {
    min-width: 100%;
  }
  .new_slider .slides_wrapper .slides .slide.active {
    opacity: 1;
    visibility: visible;
  }
  .new_slider .slides_wrapper .slides .slide.visible {
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    visibility: visible;
  }
  .new_slider .slides_wrapper .slides .slide:focus {
    outline: 0;
  }
  .new_slider .slide.animated {
    -webkit-transition: -webkit-transform 300ms ease-out;
    transition: -webkit-transform 300ms ease-out;
    transition: transform 300ms ease-out;
    transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
  }
  .new_slider .navigation {
    display: -webkit-box;
    display: flex;
    z-index: 30;
  }
  .new_slider .navigation .slide_prev,
  .new_slider .navigation .slide_next {
    background-repeat: no-repeat;
    bottom: 0;
    cursor: pointer;
    display: block;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.375rem;
    height: 1.375rem;
    z-index: 20;
  }
  .new_slider .navigation .slide_prev.hidden,
  .new_slider .navigation .slide_next.hidden {
    cursor: default;
    opacity: 0;
  }
  .new_slider .navigation .slide_prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 20px solid #CD1719;
    border-bottom: 15px solid transparent;
  }
  .new_slider .navigation .slide_next {
    right: 0;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 20px solid #CD1719;
    border-bottom: 15px solid transparent;
  }
  .newsletter_quick_subscribe form {
    display: block;
  }
  .newsletter_quick_subscribe .entry {
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #fff;
    border: 0.0625rem solid #dddddd;
    border-radius: 0;
    box-shadow: 0 0 0 #dddddd inset;
    color: #1D1D1B;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"]:focus {
    background: #fff;
    border-color: #CD1719;
    box-shadow: 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
    color: #1D1D1B;
    outline: 0;
  }
  .newsletter_quick_subscribe button {
    background: #CD1719;
    border: 0.0625rem solid #CD1719;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0 1.5em;
  }
  .newsletter_quick_subscribe button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .newsletter_quick_subscribe button .icon {
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .newsletter_quick_subscribe button .icon svg {
    fill: #fff;
  }
  .newsletter_quick_subscribe button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .newsletter_quick_subscribe button .icon + .text {
    padding-left: 0.5em;
  }
  .newsletter_quick_subscribe button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
    background: #DA5B41;
    border-color: #DA5B41;
    color: #fff;
  }
  .newsletter_quick_subscribe button:focus .icon {
    fill: #fff;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    margin-top: 0.3125rem;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #1D1D1B;
    display: block;
    height: 0.25rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(29, 29, 27, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.5em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    right: 0;
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .offcanvas_menu .offcanvas_menu_content > ul {
    padding: 0.75em 0;
    text-transform: uppercase;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul a {
    padding: 0.375em 1.5em 0.375em 2.25em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #1D1D1B;
    display: block;
    font-size: 112.5%;
    font-weight: 400;
    line-height: 2.75rem;
    padding: 0 1.5em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    background: #f6f6f6;
    color: #CD1719;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.hover,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    background: #f6f6f6;
    color: #CD1719;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
    font-size: 85%;
    text-transform: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #1D1D1B;
    display: block;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    background: #f6f6f6;
    color: #CD1719;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.hover,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    background: #f6f6f6;
    color: #CD1719;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    display: block !important;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul li a {
    padding-left: 2.5rem !important;
  }
  .offcanvas_menu .offcanvas_menu_content .offcanvas_search {
    display: -webkit-box;
    display: flex;
  }
  .offcanvas_menu .offcanvas_menu_content .offcanvas_search .icon {
    margin-right: 0.75em;
  }
  .offcanvas_menu .offcanvas_menu_content .call .icon {
    display: block;
    padding-left: 1.5em;
  }
  .offcanvas_menu .offcanvas_menu_content .call .icon svg {
    height: 2.5rem;
    width: 2.125rem;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.75em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(29, 29, 27, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    background: #CD1719;
    border-radius: 0 0 0 0;
    float: right;
    height: 2.2rem;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(29, 29, 27, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.2rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    background-color: #f6f6f6;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(246, 246, 246, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.5em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #CD1719;
    border: 0.0625rem solid #CD1719;
    border-radius: 0;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #DA5B41;
    border: 0.0625rem solid #DA5B41;
    box-shadow: 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #DA5B41;
    border: 0.0625rem solid #DA5B41;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #f6f6f6;
    border-radius: 0;
    color: #1D1D1B;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #dddddd;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0 0 0;
    margin: 0;
    min-width: 2.75rem;
    padding: 0;
    width: 2.75rem;
    background: #CD1719;
    border: 0.0625rem solid #CD1719;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  form[role="search"] button .icon {
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .icon svg {
    fill: #fff;
  }
  form[role="search"] button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.5em;
  }
  form[role="search"] button[disabled] {
    background-color: #CD1719;
    border-color: #CD1719;
    color: #9f9f98;
  }
  form[role="search"] button[disabled] .icon {
    fill: #1D1D1B;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
    position: relative;
    z-index: 10;
    background: #DA5B41;
    border-color: #DA5B41;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: calc(2.75rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #dddddd;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0 #dddddd inset;
    color: #1D1D1B;
    flex-basis: 100%;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #CD1719;
    box-shadow: 0 0 0.1875rem #CD1719 inset, 0 0 0 0.1875rem rgba(205, 23, 25, 0.2);
    color: #1D1D1B;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.75em;
    padding-top: 0.375em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    height: 2.125rem;
    margin-right: 0.25rem;
    position: relative;
    width: 2.125rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #CD1719;
  }
  .toggle_search_box a {
    fill: #1D1D1B;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 2.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.75em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(29, 29, 27, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    background: #CD1719;
    border: 0.0625rem solid #CD1719;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(29, 29, 27, 0.7);
    fill: #fff;
    height: 2.75rem;
    padding: 0.25rem;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #DA5B41;
    border-color: #DA5B41;
    outline: 0;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0;
    box-shadow: 0 0 1.25rem rgba(29, 29, 27, 0.5);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    -webkit-box-flex: 0;
            flex: 0 1 3.75rem;
    height: auto;
    padding: 0 0.375em;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 125%;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: block;
  }
  .slider {
    margin-top: 4.6875rem;
    margin-bottom: 1.5em;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .slider img {
    display: block;
    width: 100%;
  }
  .slider .slider_image_wrapper {
    display: block;
    overflow: hidden;
    position: relative;
  }
  .slider .slider_image_wrapper .description_wrapper {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0 1.5em;
    position: absolute;
    text-align: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .slider .slider_image_wrapper .description_wrapper .content_wrapper {
    width: 100%;
    height: auto;
    padding: 1.5em;
  }
  .slider .slider_image_wrapper .description_wrapper .content_wrapper .sitetitle {
    font-size: 1.125rem;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .slider .slider_image_wrapper .description_wrapper .content_wrapper .description {
    margin: 0 auto;
    max-width: 43.75rem;
  }
  .slider .slide {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: 0;
  }
  .slider .slide picture {
    overflow: hidden;
  }
  .slider .slide img {
    -webkit-transition: -webkit-transform 15000ms;
    transition: -webkit-transform 15000ms;
    transition: transform 15000ms;
    transition: transform 15000ms, -webkit-transform 15000ms;
  }
  .slider .slide_anchor:checked + .slide {
    opacity: 1;
    position: relative;
    z-index: 5;
  }
  .slider .control_slides {
    background: rgba(255, 255, 255, 0.7);
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 2.5rem;
    z-index: 15;
  }
  .slider .control_slides:hover {
    opacity: 0.3;
  }
  .slider .control_slides svg {
    fill: #1D1D1B;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    padding: 0.3125rem;
    display: none;
  }
  .slider .control_slides svg:focus,
  .slider .control_slides svg:hover {
    outline: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .slider .prev_slide {
    left: 1.25rem;
  }
  .slider .next_slide {
    right: 1.25rem;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .social_media_links a {
    border-radius: 0;
    display: inline-block;
    margin-right: 0.25rem;
  }
  .social_media_links a:hover {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .social_media_links a:hover .icon {
    fill: #CD1719;
  }
  .social_media_links a .icon {
    display: block;
    fill: #1D1D1B;
    height: 2.5rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 2.5rem;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 3em 0 3em;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0.375em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #1D1D1B;
    cursor: pointer;
    display: block;
    margin-right: 0.75em;
    padding-right: 0.75em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 400;
    padding-left: 0.75em;
  }
  .social_media_share a.twitter .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
  .social_media_share a.twitter .icon svg {
    fill: #1da1f2;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    height: 2.5rem;
    text-align: center;
    width: 2.5rem;
  }
  .social_media_share a.print .icon svg {
    fill: #CD1719;
    height: 2.5rem;
    width: 1.875rem;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.5em;
  }
  .steuernews_preview .entry:hover {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .steuernews_preview .entry:hover img {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  .steuernews_preview a .title {
    margin-bottom: 0;
    padding: 0.75em 0;
    text-decoration: none;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    overflow: hidden;
  }
  .steuernews_preview .image img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #CD1719;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_search {
    margin: 0 0 1.5em;
  }
  .steuernews_search .search {
    margin: 0 0 0.75em;
  }
  .steuernews_video_overview {
    margin-bottom: 1.5em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.75em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #CD1719;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_sidebar {
    margin: 0 0 0.75em;
  }
  .steuernews_sidebar a {
    margin-bottom: 0.375em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.75em;
  }
  .steuernews_current_issue a {
    text-decoration: none;
  }
  .steuernews_current_issue a:hover {
    text-decoration: underline;
  }
  .highlight_keyword {
    background: #CD1719;
    color: #fff;
    outline: 0.125rem solid #CD1719;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.75em;
  }
  .team_overview .title {
    letter-spacing: calc((1.128125rem + 0.11874999999999991
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 1.128125rem;
    color: #CD1719;
    font-weight: 400;
    line-height: 1.35;
    margin: 0 0 0.75em 0;
    padding: 0;
  }
  .team_overview .photo_wrapper {
    position: relative;
    margin: 0 1.5em 0.75em 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    overflow: hidden;
  }
  .team_overview .photo_wrapper:hover .contact {
    opacity: 1;
  }
  .team_overview .photo_wrapper:hover::before {
    top: 0;
  }
  .team_overview .photo_wrapper .contact {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transition: 300ms;
    transition: 300ms;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    width: 100%;
  }
  .team_overview .photo_wrapper .contact .call {
    color: #fff;
  }
  .team_overview .photo_wrapper .contact .button_contact {
    margin-top: 0.75em;
  }
  .team_overview .photo_wrapper::before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .team_overview .photo_wrapper .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
  }
  .team_overview .photo_wrapper .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.75em;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .teaser_overview {
    padding: 2.25em 0 1.5em;
  }
  .teaser_overview .teaser a {
    text-decoration: none;
  }
  .teaser_overview .teaser a:hover {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .teaser_overview .teaser a:hover img {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  .teaser_overview .teaser p {
    color: #1D1D1B;
  }
  .teaser_overview .teaser .image {
    overflow: hidden;
  }
  .teaser_overview .teaser .image img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .startpage_teaser .row {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .teaser_thank_you .teaser {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
            align-items: center;
    margin-bottom: 0.75em;
  }
  .teaser_thank_you .teaser.last_teaser .title {
    color: #CD1719;
  }
  .teaser_thank_you .teaser .icon {
    background-color: #CD1719;
    border-radius: 50px;
    padding: 0.375em;
    margin-right: 1.5em;
    flex-shrink: 0;
    height: 2.8125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.8125rem;
  }
  .teaser_thank_you .teaser .icon svg {
    fill: #fff;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #f6f6f6;
    border: 0.0625rem solid #dddddd;
    border-radius: 0;
    color: #1D1D1B;
    font-size: 85%;
    font-weight: 400;
    line-height: 2.0625rem;
    padding: 0 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #f6f6f6;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #dddddd;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .deubner_videos > .title {
    display: block;
    text-align: center;
  }
  .deubner_videos .deubner_video {
    margin-bottom: 1.5em;
  }
  .deubner_videos .deubner_video .title {
    letter-spacing: calc((0.9375rem + 0.0625
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    margin: 0 0 0.75em;
    text-transform: none;
  }
  .deubner_videos .deubner_video .video_wrapper {
    margin: 0;
  }
  /*startpage {
    .deubner_videos {
        .slide {
            .title {
                text-align: justify;
            }
        }
    }
}*/
  .video_overview .entry,
  .video_infolists_overview .entry,
  .video_infolist_overview .entry,
  .video_tutorial_overview .entry {
    display: inline-block;
  }
  .video_overview .entry:hover,
  .video_infolists_overview .entry:hover,
  .video_infolist_overview .entry:hover,
  .video_tutorial_overview .entry:hover {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .video_overview .entry:hover .video_play .icon,
  .video_infolists_overview .entry:hover .video_play .icon,
  .video_infolist_overview .entry:hover .video_play .icon,
  .video_tutorial_overview .entry:hover .video_play .icon {
    fill: #CD1719;
  }
  .video_overview .image,
  .video_infolists_overview .image,
  .video_infolist_overview .image,
  .video_tutorial_overview .image,
  .video_overview figure,
  .video_infolists_overview figure,
  .video_infolist_overview figure,
  .video_tutorial_overview figure {
    margin: 0.75em 0;
  }
  .video_overview figcaption,
  .video_infolists_overview figcaption,
  .video_infolist_overview figcaption,
  .video_tutorial_overview figcaption {
    font-size: 100%;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play,
  .video_infolist_overview .video_play,
  .video_tutorial_overview .video_play {
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon,
  .video_infolist_overview .video_play .icon,
  .video_tutorial_overview .video_play .icon {
    fill: #52524c;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    opacity: .9;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .video_overview a,
  .video_infolists_overview a,
  .video_infolist_overview a,
  .video_tutorial_overview a {
    color: #1D1D1B;
    text-decoration: none;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  a.button.video_channel {
    background: #CD1719;
    border-color: #710d0e;
    color: #fff;
  }
  a.button.video_channel .icon {
    fill: #fff;
  }
  .video_preview {
    margin-bottom: 0.75em;
  }
  .video_preview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.5em;
  }
  .video_preview .entry:hover {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .video_preview .entry:hover .video_play .icon {
    fill: #CD1719;
  }
  .video_preview figure {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.5em;
  }
  .video_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .teaser_text .title {
    color: #1D1D1B;
    margin-top: 0.75em;
    text-decoration: none;
  }
  .video_preview .video_play {
    position: relative;
  }
  .video_preview .video_play img {
    width: 100%;
  }
  .video_preview .video_play .icon {
    fill: #fff;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    opacity: .9;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper .overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: #f6f6f6;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 1.5em;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: 1;
  }
  .video_wrapper .overlay .text_wrapper a {
    display: block;
  }
  .video_wrapper.show_message {
    min-height: 18.75rem;
  }
  .video_wrapper.show_message .overlay {
    opacity: 1;
    visibility: visible;
  }
  .video_wrapper .text_wrapper {
    font-size: 85%;
    line-height: normal;
    overflow-y: auto;
    width: 100%;
  }
  .video_wrapper .button {
    margin-top: 1.5em;
  }
}
@media screen and (min-width: 35rem) {
  .button > span {
    font-size: 100%;
  }
}
@media screen and (min-width: 35rem) {
  .button:active > span,
  .button.active > span {
    font-size: 100%;
  }
}
@media screen and (min-width: 61.875rem) {
  .chart_organigram {
    display: block;
    margin-bottom: 0.75em;
  }
}
@media screen and (min-width: 61.875rem) {
  .edit_in_cms {
    bottom: 1.25rem;
    display: inline-block;
    background: #CD1719;
    border-radius: 0;
    color: #fff;
    padding: 0.75em 1.5em;
    position: fixed;
    right: 1.25rem;
    text-decoration: none;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    z-index: 9999;
  }
  .edit_in_cms:hover {
    background: #1D1D1B;
    color: #fff;
  }
}
@media screen and (max-width: 47.5rem) {
  html,
  body {
    height: initial!important;
    position: relative;
  }
}
@media screen and (min-width: 35rem) {
  .cookie_banner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    text-align: left;
  }
}
@media screen and (min-width: 35rem) {
  .cookie_banner .close {
    margin: 0 0.75em;
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 75rem) {
  .dropdown_menu > ul > li > a {
    font-size: 1rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li ul ul {
    left: 153%;
  }
}
@media screen and (min-width: 20rem) {
  footer .company_name {
    font-size: calc(0.9375rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .company_name {
    font-size: 1.0625rem;
  }
}
@media screen and (min-width: 20rem) {
  footer .location_wrapper .location {
    font-size: calc(0.9375rem + 0.0625
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .location_wrapper .location {
    font-size: 1rem;
  }
}
@media screen and (min-width: 93.75rem) {
  footer .service_wrapper {
    -webkit-box-align: end;
            align-items: flex-end;
  }
}
@media screen and (min-width: 20rem) {
  footer .service_wrapper p {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .service_wrapper p {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 93.75rem) {
  footer .service_wrapper p {
    text-align: right;
  }
}
@media screen and (min-width: 93.75rem) {
  footer .service_wrapper .link_wrapper {
    -webkit-box-align: end;
            align-items: flex-end;
  }
}
@media screen and (min-width: 30rem) {
  footer .service_wrapper .related_links {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 93.75rem) {
  footer .service_wrapper .related_links ul {
    padding: 0;
    text-align: right;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .service_wrapper .button_wrapper_footer {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .service_wrapper .button_wrapper_footer a {
    font-size: 100%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 35rem) {
  .file .file_button > span {
    font-size: 100%;
  }
}
@media screen and (min-width: 35rem) {
  .file [type="file"]:active ~ .file_button > span {
    font-size: 100%;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .travel_mode_radio > span {
    font-size: 100%;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    min-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    min-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
    min-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    min-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    min-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    min-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
    min-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    min-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    min-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    min-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    min-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
    min-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    min-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    min-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    min-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
    min-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    min-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    min-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    min-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    min-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
    min-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    min-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    min-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    min-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
    min-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    min-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    min-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    min-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    min-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
    min-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    min-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    min-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    min-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
    min-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    min-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    min-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    min-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    min-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
    min-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    min-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    min-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    min-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
    min-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    min-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    min-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
    min-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
    min-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
    min-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    min-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
    min-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    min-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
    min-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
    min-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
    min-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  header .nav_wrapper .mobile_nav {
    position: relative;
    right: auto;
    top: auto;
  }
}
@media screen and (min-width: 30rem) {
  header .nav_wrapper .mobile_nav .call {
    margin-right: 0.5em;
  }
}
@media screen and (min-width: 61.875rem) {
  header .nav_wrapper .mobile_nav .call {
    display: none;
  }
}
@media screen and (min-width: 30rem) {
  header .nav_wrapper .mobile_nav .call,
  header .nav_wrapper .mobile_nav .toggle_search_box,
  header .nav_wrapper .mobile_nav .social_media_links {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  header .nav_wrapper .mobile_nav .video {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  header .nav_wrapper .mobile_logo {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  header .logo_nav_wrapper {
    justify-content: space-around;
  }
}
@media screen and (min-width: 30rem) {
  .header_picture .header_description {
    padding: 0 4.5em;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_picture .header_description {
    bottom: 3.75rem;
    height: auto;
    position: absolute;
    right: 3.75rem;
    width: 25rem;
    max-width: 34.375rem;
    background: rgba(255, 255, 255, 0.7);
    padding: 1.5em;
  }
  .header_picture .header_description h1 {
    color: #1D1D1B;
  }
  .header_picture .header_description p {
    padding-bottom: 0 !important;
    color: #1D1D1B;
  }
  .header_picture .header_description .sitetitle {
    font-size: 1.5625rem !important;
    font-family: "Roboto", sans-serif;
    margin: 0 auto;
    margin-bottom: 0;
    max-width: 59.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_picture .header_description {
    width: 60%;
  }
  .header_picture .header_description .sitetitle {
    font-size: 1.875rem !important;
  }
}
@media screen and (min-width: 75rem) {
  .header_picture .header_description {
    width: 40%;
  }
}
@media screen and (min-width: 20rem) {
  .header_picture .header_description .sitetitle {
    font-size: calc(1.125rem + 2
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_picture .header_description .sitetitle {
    font-size: 3.125rem;
  }
}
@media screen and (max-width: 430px) {
  .header_picture .header_description .description {
    max-width: 12.5rem;
  }
}
@media screen and (min-width: 35rem) {
  .label_header {
    bottom: 1.5625rem;
    left: 1.5625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .label_header {
    bottom: 3.75rem;
    left: 3.75rem;
  }
}
@media screen and (min-width: 35rem) {
  .label_header.label_header_slider figure {
    margin-bottom: 0.75em !important;
  }
}
@media screen and (min-width: 35rem) {
  .label_header figure img {
    max-width: 5.625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .label_header figure img {
    max-width: inherit;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 47.5rem) {
  main {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
@media screen and (max-width: 1800px) {
  .portrait .header_picture {
    height: auto;
  }
}
@media screen and (min-width: 1800px) {
  .portrait .header_picture {
    height: auto;
  }
}
@media screen and (max-width: 1800px) {
  .portrait .folder_content {
    top: 0%;
    right: 0%;
    position: relative;
  }
  .portrait .folder_content .partner_img_small img {
    max-height: 100% !important;
  }
}
@media screen and (min-width: 1800px) {
  .portrait .folder_content {
    top: 15.625rem;
    max-width: 100%;
    right: 3.75rem !important;
  }
  .portrait .folder_content .entry img {
    width: auto;
    height: 150px;
  }
}
@media screen and (min-width: 47.5rem) {
  .portrait .folder_content a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 1650px) {
  .portrait .folder_content a img {
    width: 18.75rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .hideme_mobile {
    display: -webkit-box !important;
    display: flex !important;
  }
}
@media screen and (min-width: 47.5rem) {
  .hideme_desktop {
    display: none !important;
  }
}
@media screen and (min-width: 20rem) {
  .content_slider .slide_wrapper .slide {
    padding-right: calc(2.5rem + 2.1875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .content_slider .slide_wrapper .slide {
    padding-right: 4.6875rem;
  }
}
@media screen and (min-width: 20rem) {
  .content_slider .slide_wrapper .slide {
    padding-left: calc(2.5rem + 2.1875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .content_slider .slide_wrapper .slide {
    padding-left: 4.6875rem;
  }
}
@media screen and (min-width: 20rem) {
  .new_slider .slides_wrapper {
    padding-right: calc(2.5rem + 2.1875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .new_slider .slides_wrapper {
    padding-right: 4.6875rem;
  }
}
@media screen and (min-width: 20rem) {
  .new_slider .slides_wrapper {
    padding-left: calc(2.5rem + 2.1875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .new_slider .slides_wrapper {
    padding-left: 4.6875rem;
  }
}
@media screen and (min-width: 35rem) {
  .newsletter_quick_subscribe form {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 35rem) {
  .newsletter_quick_subscribe button > span {
    font-size: 100%;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 61.8125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 35rem) {
  form[role="search"] button > span {
    font-size: 100%;
  }
}
@media screen and (min-width: 30rem) {
  .toggle_search_box {
    margin-right: 0.5em;
  }
}
@media screen and (min-width: 30rem) {
  .slider .slider_image_wrapper .description_wrapper {
    padding: 0 4.5em;
  }
}
@media screen and (min-width: 47.5rem) {
  .slider .slider_image_wrapper .description_wrapper {
    background: rgba(255, 255, 255, 0);
    color: #1D1D1B;
  }
}
@media screen and (min-width: 47.5rem) {
  .slider .slider_image_wrapper .description_wrapper .content_wrapper {
    bottom: 3.75rem;
    height: auto;
    position: absolute;
    right: 3.75rem;
    width: 21.875rem;
    max-width: 28.125rem;
    background: rgba(255, 255, 255, 0.7);
  }
}
@media screen and (min-width: 61.875rem) {
  .slider .slider_image_wrapper .description_wrapper .content_wrapper {
    width: 60%;
  }
}
@media screen and (min-width: 75rem) {
  .slider .slider_image_wrapper .description_wrapper .content_wrapper {
    width: 40%;
  }
}
@media screen and (min-width: 20rem) {
  .slider .slider_image_wrapper .description_wrapper .content_wrapper .sitetitle {
    font-size: calc(1.125rem + 0.75
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .slider .slider_image_wrapper .description_wrapper .content_wrapper .sitetitle {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .slider .control_slides {
    width: 3.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .slider .control_slides {
    width: 4.375rem;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_links a {
    margin-right: 0.5em;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (max-width: 75rem) {
  aside .steuernews_preview .hidme_mobile {
    display: none;
  }
}
@media screen and (min-width: 75rem) {
  .news_image {
    float: right;
    margin-left: 1.5em;
  }
  .news_image + * {
    padding-top: 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .title {
    font-size: calc(1.128125rem + 0.11874999999999991
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .title {
    font-size: 1.246875rem;
  }
}
@media screen and (min-width: 75rem) {
  .teaser_overview .teaser h2 {
    word-wrap: normal;
  }
}
@media screen and (min-width: 20rem) {
  .deubner_videos .deubner_video .title {
    font-size: calc(0.9375rem + 0.0625
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .deubner_videos .deubner_video .title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .video_preview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.75em;
    max-width: 11.25rem !important;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .company_logo {
    display: block;
    max-width: 18.75rem;
  }
  .company_logo img {
    display: block;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
